<template>
  <div>
    <h3 class="font-weight-bolder text-body">
      {{ $t(STOCK_ITEMS) }}
    </h3>
    <div v-if="stockItems.length">
      <div
        v-for="(item, idx) in stockItems"
        :key="idx"
        class="mb-2 bg-white"
        style="padding: 12px 8px 12px; border-radius: 5px"
      >
        <div
          style="cursor: pointer; height: 36px"
          @click="toggle(`item-${idx}-chervon-icon`, `item-${idx}`, stockItems[idx], 'isCollapseOpen')"
        >
          <b-row>
            <b-col cols="6">
              <div
                class="d-flex align-items-center"
                style="height: 36px"
              >
                <feather-icon
                  :id="`item-${idx}-chervon-icon`"
                  icon="LArrowDownIcon"
                  class="feather-chevron when-opened mr-1"
                  style="transform: rotate(-90deg); margin-top: -3px; min-width: 14px"
                />
                <div
                  v-if="!stockItems[idx]['isCollapseOpen']"
                  class="d-flex"
                  style="gap: 8px"
                >
                  <span
                    class="d-inline-block"
                    style="min-width: 100px"
                  >
                    {{ getValueFromGivenObjectByKey(item, 'sku', '&mdash;') }}
                  </span>
                  <span
                    v-b-tooltip.noninteractive.hover
                    class="name-cell"
                    :title="getValueFromGivenObjectByKey(item, 'name', '&mdash;')"
                  >
                    {{ getValueFromGivenObjectByKey(item, 'name', '&mdash;') }}
                  </span>
                </div>
                <div v-else>
                  <h4 class="font-weight-bolder text-body">
                    {{ $t(ITEMS_ROW_ITEM_TITLE) + ' ' + Number(idx + 1) }}
                  </h4>
                </div>
              </div>
            </b-col>
            <b-col
              v-if="!stockItems[idx]['isCollapseOpen']"
              cols="6"
            >
              <div class="d-flex align-items-center justify-content-between item-row-on-close-collapse">
                <increment-decrement-amount
                  :item="item"
                  :min-qty="0"
                  key-name-of-amount="amount"
                  label="Available Units"
                />
                <component
                  :is="stockItemsFields['price'].type"
                  v-model="stockItems[idx]['price']"
                  v-bind="getProps('price', stockItemsFields)"
                  @click.stop
                />
              </div>
            </b-col>
          </b-row>
        </div>
        <b-collapse
          :id="`item-${idx}`"
          :ref="`item-${idx}`"
          v-model="stockItems[idx]['isCollapseOpen']"
        >
          <div class="mt-1">
            <b-row>
              <b-col cols="6">
                <div
                  v-for="({key, label}, stockItemIndex) in stockItemsItemObject"
                  :key="stockItemIndex"
                >
                  <b-row style="margin-bottom: 5px">
                    <b-col cols="6">
                      <b>{{ label }}</b>
                    </b-col>
                    <b-col cols="6">
                      {{ getValueFromGivenObjectByKey(item, key, '&mdash;') }}
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col cols="6">
                <increment-decrement-amount
                  :item="item"
                  key-name-of-amount="amount"
                  label="Available Units"
                />
                <component
                  :is="stockItemsFields[field].type"
                  v-for="field in [
                    'price',
                    'discount',
                    'supplier_sku',
                  ]"
                  :key="field"
                  v-model="stockItems[idx][field]"
                  v-bind="getProps(field, stockItemsFields)"
                />
                <div
                  v-for="field in [
                    'mpn',
                  ]"
                  :key="field"
                >
                  <component
                    :is="stockItemsFields[field].type"
                    v-model="stockItems[idx][field]"
                    :is-editable="stockItems[idx].isEditableMpn"
                    v-bind="getProps(field, stockItemsFields)"
                  />
                </div>

                <component
                  :is="stockItemsFields[field].type"
                  v-for="field in [
                    'available_on',
                  ]"
                  :key="field"
                  v-model="stockItems[idx][field]"
                  v-bind="getProps(field, stockItemsFields)"
                />

              </b-col>
            </b-row>
          </div>
        </b-collapse>
      </div>
    </div>
    <div
      v-else
      style="height: 60px"
    >
      {{ $t('No Stock Items') }}
    </div>
  </div>
</template>

<script>
import {
  BCol, BRow, BCollapse, VBToggle, VBTooltip,
} from 'bootstrap-vue'
import minConfig from '@/views/main/orders/view/purchase/config'
import { getProps, getValueFromGivenObjectByKey } from '@core/utils/utils'
import IncrementDecrementAmount from '../../common/IncrementDecrementAmount.vue'
import config from '../config'

export default {
  name: 'Items',
  components: {
    BCol,
    BRow,
    BCollapse,
    IncrementDecrementAmount,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  computed: {
    stockItems() {
      return this.$store.state[this.MODULE_NAME].quote.products ?? []
    },
    receiveWarehouse() {
      return this.$store.state[this.MODULE_NAME].receiveWarehouseForm
    },
  },
  methods: {
    toggle(id, collapseToggler, item, itemKey) {
      this[collapseToggler] = !this[collapseToggler]

      if (this[collapseToggler]) {
        document.getElementById(id).style.transform = 'rotate(0)'
        document.getElementById(id).style.transition = 'all 0.2s ease'
        // eslint-disable-next-line no-param-reassign
        item[itemKey] = true
      } else {
        document.getElementById(id).style.transform = 'rotate(-90deg)'
        // eslint-disable-next-line no-param-reassign
        item[itemKey] = false
      }
    },
  },
  setup() {
    const {
      STOCK_ITEMS, stockItemsFields, ITEMS_ROW_ITEM_TITLE, stockItemsItemObject,
    } = config()
    const { MODULE_NAME } = minConfig()

    return {
      getProps,
      MODULE_NAME,
      STOCK_ITEMS,
      stockItemsFields,
      ITEMS_ROW_ITEM_TITLE,
      stockItemsItemObject,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
<style lang="scss">
.name-cell {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 24px;
}

.item-row-on-close-collapse {

  & label,
  & .form-group {
    margin: 0 !important;
  }

  & label {
    top: 0;
  }

  & .form-group {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
</style>
